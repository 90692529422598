import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { PagesIndexQuery } from "@/types"
import Layout from "@components/layouts"
import Message from "@components/common/message"
// ______________________________________________________
//
type Props = {
  data: PagesIndexQuery
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <Layout env={props.data.site?.siteMetadata?.env ?? ""}>
    <Helmet title={props.data.site?.siteMetadata?.title ?? ""} />
    <Message
      title={props.data.site?.siteMetadata?.title ?? ""}
      text="TSConf JP is a TypeScript conference in Japan powered by TypeScript JP. This is the first event of TSConf in Japan."
    />
  </Layout>
)

// ______________________________________________________
//
export const pageQuery = graphql`
  query PagesIndex {
    site {
      siteMetadata {
        env
        title
      }
    }
  }
`
// ______________________________________________________
//
export default Component
